.container {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background-color: #fff;
}

.textCont {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textCont img {
  margin-bottom: 4px;
}

.textCont p:nth-child(2) {
  font-size: 28px;
  font-weight: 700;
}

.textCont p:nth-child(3) {
  font-size: 16px;
  font-weight: 400;
}

.btn {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 24px;
  margin-top: 20px !important;
  cursor: pointer;
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
}

.btn:hover {
  background-color: var(--primaryHover);
}
